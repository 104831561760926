<template>
  <div>
    <v-data-table
      :headers="filteredHeaders"
      :items="filteredSongs"
      @click:row="rowClicked"
      :item-class="rowClass"
      disable-pagination
      :hide-default-header="!isUserAuthenticated"
      hide-default-footer
      fixed-header
      class="elevation-1"
      mobile-breakpoint="0"
      :search="searchText"
      :custom-filter="customSearch"
      no-data-text="Loading GG's creations..."
    >
      <template v-slot:top v-if="isUserAuthenticated">
        <v-toolbar flat>
          <v-toolbar-title>Song List</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <confirmation-dialog
              title="Are you sure you want to delete this item?"
              @cancel="closeDelete"
              @ok="deleteItemConfirm"
            >
            </confirmation-dialog>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:header.yt_id="{}">
        <v-icon color="grey">mdi-link</v-icon>
      </template>
      <template v-slot:item.yt_id="{ item }">
        <div @click="imgClicked(item)" class="d-flex align-center">
          <v-btn v-if="$vuetify.breakpoint.mdAndUp" icon color="grey lighten-1" elevation="24" class="mr-2">
            <v-icon>mdi-play</v-icon>
          </v-btn>
          <v-img
            :width="$vuetify.breakpoint.mdAndUp? 24 : 64"
            :src="`//img.youtube.com/vi/${item.yt_id}/default.jpg`"
            class="my-1"
            :class="$vuetify.breakpoint.mdAndUp? 'mx-2' : ''"
          ></v-img>
        </div>
      </template>
      <template v-slot:item.title="{ item }">
        {{ item.title }} <span class="grey--text">| {{ item.title_bn}}</span>
        <span v-if="isUserAuthenticated" class="grey--text"> | {{ item.doc_id}}
          <v-icon v-if="item.lyrics_bn" class="ml-2" small>mdi-text</v-icon>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="playerDialog"
      :width="$vuetify.breakpoint.mdAndUp ? 980 : 420"
      persistent
      overlay-color="secondary darken-4"
      overlay-opacity=".4"
      transition="dialog-bottom-transition"
    >
      <song-player :song="currentSong" @close="currentSong = {}; playerDialog = false"></song-player>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import firebase from 'firebase'
import downloadjs from 'downloadjs'

export default {
  props: {
    songList: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    selectedSingers: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    searchText: {
      type: String,
      required: false,
      default: ''
    },
    sortBy: {
      type: String,
      required: true,
      default: 'title'
    },
    sortDesc: {
      type: Boolean,
      required: true,
      default: true
    }
  },

  data: () => ({
    dialog: false,
    dialogDelete: false,
    playerDialog: false,
    headers: [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'yt_id',
        width: '100'
      },
      {
        text: 'Title',
        align: 'start',
        // sortable: false,
        value: 'title'
      },
      {
        text: 'Singer',
        align: 'start',
        // sortable: false,
        value: 'singer'
        // filter: (item) => {}
      },
      {
        text: 'Album',
        align: 'start',
        sortable: false,
        value: 'album',
        hide: true
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        hide: true
      },
      {
        text: 'Rating',
        value: 'rating',
        sortable: true,
        align: 'center',
        hide: true
      }
    ],
    editedIndex: -1,
    editedSong: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    defaultSong: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    },
    currentSong: {}
  }),

  computed: {
    ...mapGetters(['isDarkMode', 'isUserAuthenticated']),
    filteredSongs () {
      let songList = [...this.songList]
      if (this.selectedSingers.length === 1 && this.selectedSingers[0] === '!GG') {
        songList = songList.filter(song => song.singer !== 'Goutam Ghosal')
      } else if (this.selectedSingers.length !== 0) {
        songList = songList.filter(song => {
          return (
            this.selectedSingers.filter(
              (singer) => song.singer.indexOf(singer) >= 0
            ).length > 0
          )
        })
      }
      const songListNull = songList.filter(song => !song[this.sortBy])
      songList = songList.filter(song => song[this.sortBy])
      songList.sort((a, b) => {
        if (!b[this.sortBy]) return 1

        let _a = '' + a[this.sortBy]
        let _b = '' + b[this.sortBy]

        if (['rating', 'release_year'].indexOf(this.sortBy) >= 0) {
          _a = parseInt(_a)
          _b = parseInt(_b)
          return this.sortDesc ? _b - _a : _a - _b
        }
        return this.sortDesc ? _b.localeCompare(_a) : _a.localeCompare(_b)
      })
      return [...songList, ...songListNull]
    },
    filteredHeaders () {
      if (this.isUserAuthenticated) return this.headers
      return this.headers.filter((header) => !header.hide)
    },
    formTitle () {
      return this.editedIndex === -1 ? 'New Song' : 'Edit Song'
    }
  },

  mounted () {
    setTimeout(() => {
      // Open a particular song from shared link
      const songId = this.$route.query.song_id || ''
      if (!songId) return
      const song = this.songList.find(song => song.song_id === songId)
      if (song) {
        this.openSong(song)
        this.$router.replace({ query: null })
      }
    }, 1000)
  },

  watch: {
    selectedSingers () {
      // _selectedSingers = [...this.selectedSingers]
    },
    sortBy () {
      // this.filteredSongs()
    },
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },

  created () {},

  methods: {
    ...mapActions(['addMultipleSongs']),
    imgClicked (song) {
      this.openSong(song)
    },
    rowClicked (song) {
      if (!this.isUserAuthenticated) {
        this.openSong(song)
      }
    },
    openSong (song) {
      this.currentSong = {
        song_id: song.song_id,
        title: song.title,
        title_bn: song.title_bn,
        singer: song.singer,
        production: song.production,
        link: `https://www.youtube.com/embed/${song.yt_id}`,
        composer: song.composer,
        lyricist: song.lyricist,
        album: song.album,
        release_year: song.release_year
      }
      this.playerDialog = true
    },

    editItem (item) {
      this.$emit('edit', { ...item })
    },

    deleteItem (item) {
      this.editedIndex = this.songList.indexOf(item)
      this.editedSong = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.songList.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedSong = Object.assign({}, this.defaultSong)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedSong = Object.assign({}, this.defaultSong)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.songList[this.editedIndex], this.editedSong)
      } else {
        this.songList.push(this.editedSong)
      }
      this.close()
    },

    rowClass () {
      return this.isUserAuthenticated ? '' : 'cursor-pointer'
    },

    customSearch (value, search, item) {
      return ['title', 'title_bn', 'singer', 'composer', 'lyricist', 'album'].map(entry => item[entry])
        .some(v => v && v.toString().toLowerCase().includes(search.toLowerCase()))
    },

    downloadSongs () {
      downloadjs(JSON.stringify(this.songList), 'GG_songs.json', 'text/plain')
      firebase.firestore()
        .collection('lyrics')
        .get()
        .then(snapshot => {
          const lyricList = snapshot.docs.map(doc => {
            const data = { ...doc.data() }
            delete data.id
            return data
          })
          downloadjs(JSON.stringify(lyricList), 'GG_songs_lyrics.json', 'text/plain')
        })
    },

    uploadSongs () {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = '.json'
      input.onchange = evt => {
        var file = evt.target.files[0]
        var reader = new FileReader()
        reader.readAsText(file)
        reader.onload = async readerEvent => {
          try {
            const content = readerEvent.target.result
            const songList = JSON.parse(content)
            await this.addMultipleSongs(songList)
            this.showToast({ text: 'Songs uploaded successfully', color: 'success' })
          } catch (err) {
            this.showToast({ text: 'Could not upload songs', color: 'error' })
            console.error(err)
          }
        }
      }
      input.click()
    }
  },
  components: {
    SongPlayer: () => import('./SongPlayer'),
    ConfirmationDialog: () =>
      import('@/components/utils/dialog/ConfirmationTemplate')
  }
}
</script>
